<template>
  <div id="approvalPage">
    <!-- // logout button -->
    <el-button slot="reference" type="danger" size="small" @click="onLogout()" style="position: fixed; top: 0; right: 10px; margin: 20px">Logout</el-button>

    <div class="pageContainer">
      <!-- Mobile Only - Hero Image -->
      <img class="mobileHeroImg" src="https://static.growlytics.in/dashboard-assets/assets/img/approval-page-image.png" />

      <!-- Left Side Image -->
      <div class="leftSide">
        <div class="header">Hi {{ $store.state.token.firstName }} 👋 <br />Thanks for signing up.</div>

        <div class="body">Hi {{ $store.state.token.firstName }}, thank for signing up. Our onboarding process is currently manual to provide you best onboarding experience possible. <br /><br />We will reachout out to you soon on the contact details you provided within 24 hours, to setup your account and give you a personalized demo of Growlytics Platform. <br /><br />In case if you don't hear from us or you want to schedule call on priority, you can always reachout to us via chat bubble at bottom right corner.</div>
      </div>
      <!-- Right Side Image -->
      <div class="rightSide">
        <img src="https://static.growlytics.in/dashboard-assets/assets/img/approval-page-image.png" />
      </div>
    </div>
  </div>
</template>
<script>
import approvalComponent from './approvalComponent';
export default approvalComponent;
</script>

<style lang="scss" src="./approval.scss"></style>
