import authService from '@/services/auth';

export default {
  name: 'forgotPassword',
  data() {
    return {};
  },
  methods: {
    onLogout() {
      authService.logout();
      this.$router.replace('/login');
    }
  },

  created() {}
};
